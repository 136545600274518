<template>
  <div class="app"></div>
</template>

<script>
  export default {
    name: "logout",
    created() {

    },
    mounted() {
      this.returnLogin();
    },
    methods: {
      returnLogin() {
        this.common.removeAuth();
        this.$router.push({name: "login"});
      }
    }
  }
</script>

<style scoped>

</style>
